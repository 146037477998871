import { createAsyncThunk } from '@reduxjs/toolkit';
import { ValidationErrors } from '@/interfaces/common';
import { axiosData } from '@/axios';

import type { AxiosError, AxiosResponse } from 'axios';

const modulePrefix = '/videos';

export interface VideoData {
  Nombre: string;
  IdVideo: number;
  video_url: string;
  IdEstado: number;
}

export interface VideoResponse {
  data: VideoData[];
}

export interface VideoProps {
  order?: string;
  activo?: number;
}

export const getVideos = createAsyncThunk(
  `${modulePrefix}/getVideos`,
  async ({ order, activo }: VideoProps, { rejectWithValue }) => {
    try {
      const data: AxiosResponse = await axiosData.get(`/videos?limit=1000&order=orden`, {});
      const result: VideoResponse = data.data;
      return result;
    } catch (err: any) {
      const error: AxiosError<ValidationErrors> = err;
      if (!error.response) {
        throw error;
      }
      return rejectWithValue(error.response.data);
    }
  },
);
