import React, { useEffect, useState } from 'react';

import { useAppDispatch, useAppSelector } from '@/hooks/redux';
import { useNavigate } from 'react-router-dom';
import { getVideos } from '@/store/videos/videosActions';
import EmpresaForm from '@/components/organisms/EmpresaForm';

const Video: React.FC = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const dataVideos = useAppSelector(state => state.videos?.itemsData);

  const [currentPage, setCurrentPage] = useState(1);

  // Resto del código

  const itemsPerPage = 9;
  const dataConsultoresCount = dataVideos?.data.filter(data => data.IdEstado === 1).length ?? 0;
  const totalPages = Math.ceil(dataConsultoresCount / itemsPerPage);

  const generateVisiblePages = (maxPagesToShow: number) => {
    const halfMaxPagesToShow = Math.floor(maxPagesToShow / 2);
    let startPage = Math.max(1, currentPage - halfMaxPagesToShow);
    const endPage = Math.min(totalPages, startPage + maxPagesToShow - 1);

    if (endPage - startPage + 1 < maxPagesToShow) {
      startPage = Math.max(1, endPage - maxPagesToShow + 1);
    }

    const pagesArray = [...Array(endPage - startPage + 1)].map((_, index) => startPage + index);

    return pagesArray;
  };

  const handlePageClick = (pageNumber: number) => {
    setCurrentPage(pageNumber);
  };

  const getCurrentItems = () => {
    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    return dataVideos?.data.filter(data => data.IdEstado === 1).slice(indexOfFirstItem, indexOfLastItem);
  };

  useEffect(() => {
    dispatch(getVideos({ activo: 1 }));
  }, [dispatch]);

  return (
    <>
      {/* Breadcrumb */}
      <section className="container d-none d-lg-block mt-2">
        <div className="row">
          <div className="col-12">
            <div className="border-top py-2">
              <nav style={{}} aria-label="breadcrumb" className="d-none d-md-block">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <a href="/">Inicio</a>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">
                    Videos
                  </li>
                </ol>
              </nav>
            </div>
          </div>
        </div>
      </section>
      {/* Header */}
      <header className="container my-2 my-md-3">
        <div className="row text-center mb-5">
          <div className="col-md-12">
            <h1 className="display-3 mb-4">Videos</h1>
            <p className="lead mb-5">
              <a href="https://www.youtube.com/@humanopuente" target="_blank">
                <i className="bi bi-youtube" /> Suscribite a nuestro canal de YouTube
              </a>
            </p>
          </div>
          {getCurrentItems()
            ?.filter(data => data.IdEstado === 1)
            .map(data => (
              <div key={data.IdVideo} className="col-12 col-sm-6 col-md-4 mb-4">
                <div className="embed-container border rounded">
                  <iframe src={data.video_url} frameBorder={0} />
                </div>
              </div>
            ))}

          {dataVideos && (
            <div className="row my-5">
              <div className="col">
                <nav aria-label="Page navigation example">
                  <ul className="pagination justify-content-center">
                    <li className={`page-item ${currentPage === 1 ? 'disabled' : ''}`}>
                      <button className="page-link" onClick={() => handlePageClick(currentPage - 1)} disabled={currentPage === 1}>
                        «
                      </button>
                    </li>
                    {generateVisiblePages(3).map(pageToShow => {
                      const isCurrentPage = currentPage === pageToShow;
                      return (
                        <li key={pageToShow} className={`page-item ${isCurrentPage ? 'active' : ''}`}>
                          <button className="page-link" onClick={() => handlePageClick(pageToShow)}>
                            {pageToShow}
                          </button>
                        </li>
                      );
                    })}
                    {currentPage < totalPages - 1 && (
                      <li className="page-item disabled">
                        <span className="page-link">...</span>
                      </li>
                    )}
                    <li className={`page-item ${currentPage === totalPages ? 'disabled' : ''}`}>
                      <button
                        className="page-link"
                        onClick={() => handlePageClick(currentPage + 1)}
                        disabled={currentPage === totalPages}>
                        »
                      </button>
                    </li>
                  </ul>
                </nav>
              </div>
            </div>
          )}
        </div>
      </header>
    </>
  );
};

export default Video;
